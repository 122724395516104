<template>
  <div class="not-found">
    <div class="not-found-container">
    <div>
      <router-link class="router-link" to="/">
          <div class="logo-container">
            <img
              class="logo"
              alt="Pleasmile"
              src="../assets/pleasmile.svg"/>
          </div>
      </router-link>
    </div>
    <div class="not-found-content">
      <div class="not-found-title">Seite nicht gefunden</div>
        
      </div>
      <Footer />
    </div>
  </div>
</template>

<script>
import Footer from "@/components/Footer.vue";

export default {
  name: "NotFound",
  components: {
    Footer,
  },
};
</script>

<style lang="scss" scoped>
@import "../scss/colors.scss";

.logo-container {
  max-height: 200px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 40px;
}

.logo {
  width: 200px;
  height: 87px;
}

.not-found {
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  min-height: 100vh;
  background-color: $page-background-color-primary-dark;
}

.not-found-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 100vw;
  min-height: 100vh;
}

.not-found-content {
  font-family: Dosis;
  font-weight: 400;
  font-size: 18px;
  color: $text-color-primary-light;
  max-width: 600px;
  margin: 40px 40px 40px 40px;
}

.not-found-title {
  font-family: Dosis;
  font-weight: 500;
  font-size: 32px;
  color: $text-color-primary-light;
  margin-bottom: 40px;
}
</style>
